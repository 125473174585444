import { useEffect, useState } from 'react';
import getFirebase from "./firebase";

export interface UserObj {
    user: firebase.User | null,
    resolved: boolean
}

export class User {

    private firebase: firebase.app.App

    constructor(firebase: firebase.app.App) {
        this.firebase = firebase
    }

    public observeUser(observer: (user: UserObj) => void): () => void {
        return this.firebase.auth().onAuthStateChanged((user) => {
            user
        })
    }

    public loginAn(): Promise<any> {
        return this.firebase.auth().signInAnonymously()
    }
    
    public loginA() {
        console.log('loginA')
        this.firebase.auth().onAuthStateChanged((user) => {
            console.log(user)
            if (user) {
              // User is signed in.
              var isAnonymous = user.isAnonymous;
              var uid = user.uid;
              // ...
            } else {
              // User is signed out.
              // ...
              this.firebase.auth().signInAnonymously().catch(function(error) {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                // ...
                console.error('an err', errorCode, errorMessage)
              });
            }
            // ...
          });
    }
}

export default function useUser(): User | null {
    const [user, setUser] = useState(null);
  
      useEffect(() => {
        setUser(new User(getFirebase()));
      }, []);
  
      return user;
  }