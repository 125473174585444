import React from 'react'
import SEO from "./seo"
import "./signIn.css"
import { getAuth, facebookAuthProvider } from '../model/firebase';

const SignIn = () => {

    const singInProvider = (provider: firebase.auth.AuthProvider) => (event: React.FormEvent<EventTarget>) => {
        event.preventDefault()
        getAuth().signInWithPopup(provider)
    };

    const buttonClick = (event: React.FormEvent<EventTarget>) => {
        event.preventDefault()
        getAuth().signInAnonymously()
    };

    return (
        <div className="signIn">
            <SEO title="Sign In" />
            <h2 className="signIn__headline">Sign In</h2>

            <button
                onClick={singInProvider(facebookAuthProvider())}
                className="signIn__button--facebook">
                    Sign In with Facebook
            </button>
            {/* <button
                className="center sign-in">
                    Sign In with Google
            </button> */}
            <p className="signIn__text">
                <a href="" className="signIn__link" onClick={buttonClick}>Continue anonymously</a>
                {/* <br />{" "}
                Don't have an account? <a href="" onClick={buttonClick}>Sign up here</a> */}
                {/* <br />{" "}
                <a href="#">Forgot Password?</a> */}
            </p>
        </div>
    )
}

export default SignIn