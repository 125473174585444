import React, { createContext, useState, useEffect } from "react";
import { UserObj } from "../model/User";
import { getAuth } from "../model/firebase";

const emptyUser = { user: null, resolved: false };

export const UserContext = createContext<UserObj>(emptyUser);
const UserProvider = (props) => {
    const [ user, setUser ] = useState<UserObj>(emptyUser);
    
    useEffect(() =>
        getAuth().onAuthStateChanged((user) => setUser({ user, resolved: true }))
    , []);

    return (
        <UserContext.Provider value={ user }>
            {props.children}
        </UserContext.Provider>
    )
};

export default UserProvider