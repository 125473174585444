import * as React from 'react'
import useUser from '../model/User'
import SignIn from './signIn'

const AuthComponent = () => {
  const user = useUser();
  return (
    <SignIn/>
  )
  }

export default AuthComponent
