import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/firestore';
import 'firebase/storage';
import StoneComments from './stoneComments';

// Firebase web config
const firebaseConfig = {
  apiKey: "AIzaSyBuNvq2dOUyShhJqBknXLa3FVDrDLInA6w",
  authDomain: "stones-7ff43.firebaseapp.com",
  databaseURL: "https://stones-7ff43.firebaseio.com",
  projectId: "stones-7ff43",
  storageBucket: "stones-7ff43.appspot.com",
  messagingSenderId: "141303360114",
  appId: "1:141303360114:web:654e0f6f01a883aa6230eb",
  measurementId: "G-MNXLZXY8G1"
};

// firebaseConfig.databaseURL = 'http://localhost:8080';

let instance: firebase.app.App = null;

export default function getFirebase(): firebase.app.App | null {
  if (typeof window !== 'undefined') {
    if (instance) return instance;
    instance = firebase.initializeApp(firebaseConfig);
    return instance;
  }

  return null;
}

export function getFunction(name: string): (data: any) => Promise<any> {
  return getFirebase().functions('europe-west1').httpsCallable(name)
}

export function getAuth(): firebase.auth.Auth {
  return getFirebase().auth()
}

export function facebookAuthProvider(): firebase.auth.AuthProvider {
  return new firebase.auth.FacebookAuthProvider()
}

async function getUser(): Promise<firebase.User> {
  const firebase = getFirebase()
  if (!firebase) {
    return Promise.reject('Unable to get firebase');
  }

  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      unsubscribe();
      if (!user) {
        reject('Not logged in')
      }
      resolve(user);
    }, reject)
  });
}

export async function getUploadStorage(): Promise<firebase.storage.Reference> {
  const firebase = getFirebase()
  if (!firebase) {
    return Promise.reject('Unable to get firebase');
  }

  const user = await getUser();
  return firebase.storage().ref(`upload/${user.uid}/`)
}

export function getStones(): firebase.firestore.CollectionReference {
  return getFirebase().firestore().collection('stones')
}

export function getStoneComments(stoneUid: string): firebase.firestore.CollectionReference<StoneComments> {
  return getFirebase().firestore().collection(`/stones/${stoneUid}/records`) as firebase.firestore.CollectionReference<StoneComments>
}

async function getStoneImage(stoneUid: string, image: string): Promise<string> {
  const firebase = getFirebase()
  if (!firebase) {
    return Promise.reject('Unable to get firebase');
  }

  return await firebase.storage().ref(`stones/${stoneUid}/${image}`).getDownloadURL()
}

export function getStoneImages(stoneUid: string): {
  getImage: () => Promise<string>,
  getThumbnail: () => Promise<string>
} {
  return {
    getImage: () => getStoneImage(stoneUid, '0.jpg'),
    getThumbnail: () => getStoneImage(stoneUid, 'thumb@512_0.jpg')
  }
}