/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"
import UserProvider, {UserContext} from "../context/user"
import AuthComponent from "./auth"
import Loading from "./loading"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const user = null;

  return (
    <UserProvider>
      <div className="page">
        <Header siteTitle={data.site.siteMetadata.title} />
        <div className="content">
          <main>
            <UserContext.Consumer>
              {
                (userObj) => {
                  if (userObj.user)
                    return children

                  if (userObj.resolved)
                    return <AuthComponent/>

                  return <Loading/>
                }
              }
            </UserContext.Consumer>
            </main>
        </div>
      </div>
    </UserProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
