import * as React from 'react'
import Link from 'gatsby-link'
import { UserContext } from '../context/user'
import "./header.css"

const Menu = () => {
    return (
        <UserContext.Consumer>
            {
                ({user}) => {
                    if (user)
                        return (
                            <div className="header__menu">
                                    <Link to="/">Moje kamínky</Link>
                                    <Link to="/add">Přidat</Link>
                                    <Link to="/info">Jak na to</Link>
                            </div>
                        )

                    return <></>
                }
            }
        </UserContext.Consumer>
    )
}

export default Menu